import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["overlayContent", "overlay", "header"]

  connect() {
    console.log("Hello, Stimulus!", this.element)
  }

  showHeader() {
    const header = this.headerTarget;
    if (window.scrollY >= 110) {
      console.log('showHEADER')
      header.classList.remove("-top-1/2")
      header.classList.add("top-0")
    } else {
      header.classList.add("-top-1/2")
      header.classList.remove("top-0")
    }
  }

  showVideoOverlay(e) {
    e.preventDefault();
    this.overlayTarget.innerHTML = this.bspVideo();
  }

  hideOverlay(e) {
    if (event.target !== this.overlayContentTarget && !this.overlayContentTarget.contains(event.target)) {
      this.overlayTarget.innerHTML = ''
    }
  }

  showHomesliceOverlay(e) {
    e.preventDefault();
    this.overlayTarget.innerHTML = this.homeslice();
  }

  bspVideo() {
    return `
      <div class="fixed backdrop-blur-sm bg-black/20 top-0 left-0 right-0 bottom-0 w-screen" data-action="click->home#hideOverlay">
        <div class="flex justify-center items-center h-screen">
          <iframe class="w-full aspect-video" data-home-target="overlayContent" allowtransparency="true" title="Wistia video player" allowFullscreen frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" src="https://fast.wistia.net/embed/iframe/04mo3s4s5r" width="50%" height="450"></iframe>
        </div>
      </div>
    `;
  }

  homeslice() {
    return `
      <div class="fixed backdrop-blur-sm bg-black/20 top-0 left-0 right-0 bottom-0 w-screen" data-action="click->home#hideOverlay">
        <div data-home-target="overlayContent" class="mx-auto max-w-4xl bg-white m-2 p-8 h-full overflow-scroll">
          <img src="/images/homeslice_1.jpg" alt="">
          <img src="/images/homeslice_2.jpg" alt="">
          <img src="/images/homeslice_3.jpg" alt="">
          <img src="/images/homeslice_4.png" alt="">
        </div>
      </div>      
    `;
  }
}
